import { RequestMethods } from '@constants/types';
import { BASE_URL, customerApiUrl } from '@constants/urls';
import { IAuthToken } from '@interfaces/auth/reducer';
import { capitalizeFirstLetter } from '@utils/string';

import { CALL_API } from './index';

// const REQUEST_TIMEOUT = process.env.REQUEST_TIMEOUT ? Number(process.env.REQUEST_TIMEOUT) : 15000;
type RequestMode = 'cors' | 'navigate' | 'no-cors' | 'same-origin';

interface IHeaders {
  Accept: string;
  'Cache-Control': string;
  'Content-Type': string;
  cache: string;
  credentials: string;
  'User-Agent'?: string;
}

export const getHeaders: (endpoint: string, token: IAuthToken | null, userAgent: string) => IHeaders = (endpoint, token, userAgent) => {
  const reqHeaders: IHeaders = {
    'Accept': 'application/json',
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json; charset=utf-8',
    'cache': 'no-cache',
    'credentials': 'include',

  };

  if ((endpoint.includes(BASE_URL) || endpoint.includes(customerApiUrl)) && token) {
    // @ts-ignore
    reqHeaders.Authorization = `${capitalizeFirstLetter(token.token_type)} ${token.access_token}`;
  }

  const isServer = typeof window === 'undefined' ? 1 : 0;
  if (isServer) {
    reqHeaders['User-Agent'] = userAgent;
  }

  return reqHeaders;
};

const getBody: (body: any) => string = (body) => JSON.stringify(body);

export const getFetchAction = (
  endpoint: string,
  method: RequestMethods.Any,
  body: any,
  token: IAuthToken | null,
  headers: object | null,
  userAgent: string,
): Promise<Response> => {

  const reqHeaders = getHeaders(endpoint, token || null, userAgent);
  const headersResult = headers ? { ...reqHeaders, ...headers } : { ...reqHeaders };
  const options: {
    body: any;
    headers: any;
    method: string;
    signal?: AbortSignal;
    mode?: RequestMode;
  } = {
    body: typeof body !== 'string' ? getBody(body) : body,
    headers: headersResult,
    method,
  };

  if ([RequestMethods.POST_METHOD, RequestMethods.PUT_METHOD, RequestMethods.DELETE_METHOD, RequestMethods.PATCH_METHOD].includes(method)) {
    return fetch(endpoint, options);
  }

  return fetch(endpoint, {
    headers: headersResult,
    method,
  }).catch((error) => {
    if (error.name === 'TypeError') {
      error.status = 503;
    }
    throw error;
  });
};

export const callApi = (
  endpoint,
  method,
  body,
  token: IAuthToken | null,
  headers: object | null,
  userAgent,
) => {
  return getFetchAction(endpoint, method, body, token || null, headers || null, userAgent)
    .then((response: Response) => {
      if (!response.ok && response.status === 500) {
        return ({ json: { ErrorCode: response.status }, response });
      }
      return response.json().then((json) => ({ json, response })).catch(() => {
        return ({ json: { ErrorCode: response.status }, response });
      });
    }).then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject({ body: json, status: response.status });
      }
      const errorResponse = { json, status: response.status };
      return Promise.resolve(errorResponse);
    });
};

export function actionWith(action, data) {
  const finalAction = Object.assign({}, action, data);

  delete finalAction[CALL_API];

  return finalAction;
}
