import { useRouter } from 'next/router';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { mobileMode } from '@constants/config';
import { PageTypes, productsListTypes } from '@constants/pageTypes';
import { useScrollRestore } from '@hooks/scrollRestore';
import { IStore } from '@interfaces/store';

const ScrollRestore: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const router = useRouter();

  const [isNewPageProductList, changeIsNewPageProductList] = useState<boolean>(false);
  const [routerChangeProgress, changeRouterChangeProgress] = useState<boolean>(false);

  const beforePopState = useSelector((store: IStore) => store.navigation.beforePopState);
  const beforePopStateHistory = useSelector((store: IStore) => store.navigation.beforePopStateHistory);
  const pageType = useSelector((state: IStore) => state.pageData?.type);
  const productsListLoaded = useSelector((state: IStore) => state.productsList?.loaded);

  const { setYposition, restorePosition, yPosition } = useScrollRestore();

  const restoreScroll = (y?: number) => {
    restorePosition(y);
  };

  useEffect(() => {
    if (!routerChangeProgress) {
      if (isNewPageProductList) {
        if ((yPosition && mobileMode) || productsListLoaded) {
          setTimeout(() => {
            if (!mobileMode) {
              changeIsNewPageProductList(false);
            }
            restoreScroll(yPosition || undefined);
          }, 50);
        }
      } else {
        restoreScroll();
      }
    }
  }, [routerChangeProgress]);

  useEffect(() => {
    router.events.on('routeChangeStart', (url) => {
      changeRouterChangeProgress(true);
    });
    router.events.on('routeChangeComplete', () => {
      changeRouterChangeProgress(false);
    });

  }, []);

  const beforePopStateCb = () => {
    const prevPageType = beforePopStateHistory && beforePopStateHistory.hasOwnProperty('pageType') && beforePopStateHistory.pageType;
    if (prevPageType && productsListTypes.includes(prevPageType as PageTypes)) {
      changeIsNewPageProductList(true);
    } else {
      changeIsNewPageProductList(false);
    }
    const beforePopStateHistoryScroll = beforePopStateHistory?.__scrollY;
    if (beforePopStateHistory && beforePopStateHistoryScroll && Number.isFinite(beforePopStateHistoryScroll)) {
      setYposition(beforePopStateHistoryScroll);
    }
    return true;
  };

  useEffect(() => {
    if (beforePopState) {
      beforePopStateCb();
    }
  }, [beforePopState]);

  const showOver = mobileMode && beforePopState && isNewPageProductList && pageType === PageTypes.productsList && yPosition;

  return (
    <>
      {children}
      {showOver && (
        <div style={{
          position: 'fixed',
          top: 0,
          width: '101%',
          height: '101%',
          backgroundColor: '#ffffff',
          zIndex: 999,
        }} />
      )}
    </>
  );
};

export default ScrollRestore;
