import { Reducer } from 'redux';

import { maileBeauty } from '@constants/config';
import { EGender } from '@constants/gender';
import * as navigationActions from '@constants/actions/navigation';
import * as actionsPage from '@constants/actions/page';
import * as actions from '@constants/actions/pageData';
import { ELanguages } from '@constants/languages';
import { PageTypes, productsPageTypes } from '@constants/pageTypes';
import { IWrappedCategorySectionMapped } from '@interfaces/category';
import { INavigationActions } from '@interfaces/navigation/actions';
import { IPageActions } from '@interfaces/page/actions';
import { IPageDataActions } from '@interfaces/pageData/actions';
import { IPageDataReducer } from '@interfaces/pageData/reducer';
import { mapWrappedCategory } from '@reducers/pageData/utils';

export const initialState: IPageDataReducer = {
  categoryWrapped: [],
  contentPageData: {},
  countryCode: 'EE',
  data: {},
  emptyCountryLang: true,
  languageCode: ELanguages.et,
  lastMainPage: null,
  loading: false,
  loadingPolicy: false,
  navBar: null,
  policy: [],
  shallowPageChange: false,
  type: null,
  typeNext: null,
};

export const pageDataReducer: Reducer<IPageDataReducer, IPageDataActions | INavigationActions | IPageActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.PAGE_DATA_GET_REQUEST:
      return { ...state, loading: true };
    case actions.PAGE_DATA_GET_SUCCESS:
      const data = JSON.parse(JSON.stringify(action.payload.data) ?? null);
      if (data && data.hasOwnProperty('translations')) {
        delete data.translations;
      }
      return {
        ...state,
        data,
        loading: false,
        shallowPageChange: false,
        type: action.payload.type,
      };
    case actions.PAGE_DATA_GET_FAILED:
      return { ...state, loading: false, shallowPageChange: false, type: PageTypes.error };
    case actions.PAGE_DATA_SHALLOW_UPDATE_REQUEST:
      return {
        ...state,
        data: { ...state.data, author: undefined, blogTags: [], seo: undefined },
        loading: true,
      };
    case actions.PAGE_DATA_UPDATE_REQUEST:
      return {
        ...state,
        data: { ...state.data, author: undefined, blogTags: [], seo: undefined },
        loading: true,
        //    type: state.shallowPageChange ? state.type : PageTypes.loading,
      };
    case actions.PAGE_DATA_SHALLOW_UPDATE_SUCCESS:
    case actions.PAGE_DATA_UPDATE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload.data },
        lastMainPage: productsPageTypes.includes(action.payload.type)
          ? history?.state || null
          : state.lastMainPage,
        loading: false,
        shallowPageChange: false,
        type: action.payload.type,
      };
    case actions.PAGE_DATA_UPDATE_FAILED:
      return {
        ...state, loading: false,
        shallowPageChange: false, type: PageTypes.error,
      };
    case actions.PAGE_DATA_SET_LOCALE:
      return { ...state, countryCode: action.payload.countryCode, languageCode: action.payload.languageCode };
    case actions.PAGE_DATA_SET_LANGUAGE:
      return { ...state, languageCode: action.payload };
    case actions.PAGE_DATA_SET_SEO:
      return { ...state, data: { ...state.data, seo: { ...state.data.seo, ...action.payload } } };
    case actions.PAGE_DATA_GET_CATEGORY_WRAPPED_SUCCESS:
      if(maileBeauty){
        const mapCategories: IWrappedCategorySectionMapped[] = action.payload && action.payload.length > 0 &&
          action.payload?.filter((categoryFilter) => categoryFilter.sexId === EGender.women).map((categoryGroup) => {
            const sexId = categoryGroup.sexId;
            const popularCategories = categoryGroup.popularCategories.map((popularCategory) => mapWrappedCategory(popularCategory, sexId));
            const categoryTarget = categoryGroup.categories.find((categoryGroup) => categoryGroup.categoryId === '147');
            const categories = categoryTarget?.children?.map((category) => mapWrappedCategory(category, sexId)) || [];
            return { ...categoryGroup, categories, popularCategories };
          }) || [];
        return { ...state, categoryWrapped: mapCategories };
      } else {
        const mapCategories: IWrappedCategorySectionMapped[] = action.payload && action.payload.length > 0 &&
          action.payload?.map((categoryGroup) => {
            const sexId = categoryGroup.sexId;
            const popularCategories = categoryGroup.popularCategories.map((popularCategory) => mapWrappedCategory(popularCategory, sexId));
            const categories = categoryGroup.categories.map((category) => mapWrappedCategory(category, sexId));
            return { ...categoryGroup, categories, popularCategories };
          }) || [];
        return { ...state, categoryWrapped: mapCategories };
      }
    case actions.CONTENT_PAGE_DATA_GET_SUCCESS:
      return { ...state, contentPageData: { ...state.contentPageData, [action.requestParams.type]: action.payload } };
    case actions.PAGE_DATA_GOTOPAGE:
      return { ...state, shallowPageChange: true, typeNext: action.payload.pageType };
    case actionsPage.PAGE_MOBILE_UPDATE:
      if (action.payload) {
        return { ...state, type: state.typeNext || state.type };
      } else {
        return { ...state, typeNext: null };
      }
    case navigationActions.NAVIGATION_UPDATE_STATE:
      if (state.lastMainPage) {
        return { ...state, lastMainPage: { ...state.lastMainPage, __scrollY: action.payload } };
      }
      return state;
    case actions.PAGE_DATA_PRIVACY_REQUEST:
      return { ...state, loadingPolicy: true };
    case actions.PAGE_DATA_PRIVACY_SUCCESS:
      return { ...state, loadingPolicy: false, policy: action.payload?.data?.items || [] };
    case actions.PAGE_DATA_PRIVACY_FAILED:
      return { ...state, loadingPolicy: false };
    case actions.PAGE_DATA_SITE_SETTINGS_SAVE:
      return { ...state, data: { ...state.data, siteSettings: action.payload } };
    case actions.PAGE_DATA_GET_NAVBAR_SUCCESS:
      return { ...state, navBar: action.payload?.data?.attributes || null };
    case actions.PAGE_DATA_GET_PRIVATE_POLICY_SUCCESS:
      return {
        ...state,
        data: { ...state.data, siteSettings: { ...state.data.siteSettings, privacyPolicyModal: action.payload } },
      };
    case actions.PAGE_DATA_GET_PAGES_SUCCESS:
      return {
        ...state,
        data: { ...state.data, pages: action.payload },
      };
    default:
      return state;
  }
};
