import { IAccountActions } from '@interfaces/account/actions';
import { IAlertActions } from '@interfaces/alert/actions';
import { IAuthActions } from '@interfaces/auth/actions';
import { ICartActions } from '@interfaces/cart/actions';
import { ICommonActions } from '@interfaces/common/actions';
import { IContextActions } from '@interfaces/context/actions';
import { ICallApiAction } from '@interfaces/fetchService';
import { IGiftCardActions } from '@interfaces/giftCard/actions';
import { INavigationActions } from '@interfaces/navigation/actions';
import { IPageActions } from '@interfaces/page/actions';
import { IPageDataActions } from '@interfaces/pageData/actions';
import { IPopularCategoriesActions } from '@interfaces/popularCategories/actions';
import { IProductDetailsActions } from '@interfaces/productDetails/actions';
import { IProductsListActions } from '@interfaces/productsList/actions';
import { ISearchActions } from '@interfaces/search/actions';
import { IStore } from '@interfaces/store';
import { IStoresActions } from '@interfaces/stores/actions';
import { IWishListActions } from '@interfaces/wishList/actions';
import { TNativeActions } from '@reducers/nativeSettings/actionTypes';

export interface IApiResponse<T> {
  payload: any | T;
  result: boolean;
  status: number;
}

export interface IThunkDispatch<S, E, A> {
  <T extends A>(action: T): T;

  <R>(asyncAction: TThunkAction<R, S, E, A>): R;
}

export type TThunkAction<R, S, E, A> = (
  dispatch: IThunkDispatch<S, E, A>,
  getState: () => S,
  extraArgument: E
) => Promise<R>;

export type IStoreActions =
  | IAccountActions
  | IAlertActions
  | IAuthActions
  | ICartActions
  | IContextActions
  | ICommonActions
  | IPageDataActions
  | IPageActions
  | IProductDetailsActions
  | IProductsListActions
  | IGiftCardActions
  | IStoresActions
  | ICallApiAction
  | IPopularCategoriesActions
  | IWishListActions
  | ISearchActions
  | TNativeActions
  | INavigationActions
  | Promise<IApiResponse<any> | null>;

export type TThunkResult<R> = TThunkAction<R, IStore, null, IStoreActions>;

export type TThunkDispatch = IThunkDispatch<IStore, null, IStoreActions>;

export interface ILocaleParamsShort {
  country: string;
  lang: string;
}

export interface ILocaleParamsWithCountryShort {
  countryId?: number | null;
  country: string;
  lang: string;
}
export interface IAuthParams {
  sessionId: string;
}

export enum EStoreID {
  DENIMDREAM='DENIMDREAM',
  MAILEBEAUTY='MAILEBEAUTY'
}
