import { smartIdFinalize } from '@constants/routes';
import { capitalizeFirstLetter } from '@utils/string';
import { format } from 'date-fns';

import { getNameFromFB } from '@actions/acAuth/utils';
import { acGoToPage } from '@actions/acNavigation';
import { convertCamelCaseObjectToHyphenObject } from '@components/pages/loyaltyProgram/utils';
import { CacheFetchOptions } from '@constants/cacheCallApi';
import { mobileMode } from '@constants/config';
import { customerApiDateFormat } from '@constants/dates';
import { PageTypes } from '@constants/pageTypes';
import { RequestMethods } from '@constants/types';

import {
  customerRemindFinalizesUrl, customerSmartIdAttachFinalizeUrl, customerSmartIdAttachUrl,
  customerSmartIdTokenUrl,
  customerSocialsClientsUrl,
  getCustomerForgotLinkUrl,
  getCustomerMethod,
  getCustomerResetPasswordUrl,
  getCustomerSignupDataUrl,
  loginUrl,
  passwordResetKey,
  registrationUrl,
  sessionUrlProtect,
} from '@constants/urls';
import { AccountAttributesFields } from '@interfaces/account';
import { IAppleResponseToken, IAuthPrefillProps, IFaceBookTokenDetail, ISignUpParams } from '@interfaces/auth';
import { IAuthClearSignUpErrors, IAuthSessionToken, IGaIdSet, ISessionSet } from '@interfaces/auth/actions';
import { ESocialTypes, IAuthToken } from '@interfaces/auth/reducer';
import { IUserDataAttributes } from '@interfaces/config/reducer';
import { ICallApiAction } from '@interfaces/fetchService';
import { ESideMenuPage } from '@interfaces/page/store';
import { IStore } from '@interfaces/store';
import { base64ToString2, objectToBase64 } from '@utils/base64';
import { isServer } from '@utils/context';
import { TContext } from '@utils/cookies';
import { getGenderByNationalId } from '@utils/gender';
import { removeSessionId, setSessionId } from '@utils/sessionId';
import { resetToken } from '@utils/token';

import * as actions from '../../constants/actions/auth';
import * as loyaltyProgramActions from '../../constants/actions/loyaltyProgram';
import { CALL_API } from '../../services/SSRService';
import { addParamsToUrl } from '../../services/SSRService/utils';
import { acCustomerLinkFacebookAccountLong, acFacebookMe } from '../acAccount';
import { acPageSideMenuPageAction, acPageSideMenuStateAction } from '../acPage';

export const acAuthCreateSession: (country: string, lang: string, origin: string) => ICallApiAction = (country, lang, origin) => ({
  [CALL_API]: {
    body: { country, lang },
    endpoint: addParamsToUrl(sessionUrlProtect(origin), { country, lang }),
    method: RequestMethods.POST_METHOD,
    types: [
      actions.AUTH_CREATE_SESSION_REQUEST,
      actions.AUTH_CREATE_SESSION_SUCCESS,
      actions.AUTH_CREATE_SESSION_FAILED,
    ],
  },
});

type TAuthCreateSessionAction = (context?: TContext | null) => (dispatch, getState: () => IStore) => Promise<any>;
export const acAuthCreateSessionAction: TAuthCreateSessionAction = (context = null) => async (dispatch, getState: () => IStore) => {
  const {
    context: { host },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode) {
    removeSessionId(countryCode, context);
    const origin = isServer() ? 'http://' + host : window?.location?.origin;
    return dispatch(acAuthCreateSession(countryCode, languageCode, origin)).then(async (res) => {
      const sessionId = res?.payload?.sessionId;
      if (sessionId) {
        setSessionId(countryCode, sessionId, context);
        await dispatch(acAuthSetSessionId(sessionId));
      }
      return res;
    });
  }
  return Promise.resolve(false);
};

type TSetSessionId = (sessionId: string) => ISessionSet;
export const acAuthSetSessionId: TSetSessionId = (sessionId) => ({
  payload: sessionId,
  type: actions.AUTH_SESSION_SET,
});

type TSetGaId = (gaId: string) => IGaIdSet;
export const acAuthSetGaId: TSetGaId = (gaId) => ({
  payload: gaId,
  type: actions.AUTH_GA_ID_SET,
});

type TAuthSetToken = (token: IAuthToken) => IAuthSessionToken;
export const acAuthSetToken: TAuthSetToken = (token) => ({
  payload: token,
  type: actions.AUTH_TOKEN_SET,
});

export const acCustomerGetProfile = () => (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: getCustomerMethod(),
      method: RequestMethods.GET_METHOD,
      types: [
        actions.CUSTOMER_GET_PROFILE_REQUEST,
        actions.CUSTOMER_GET_PROFILE_SUCCESS,
        actions.CUSTOMER_GET_PROFILE_FAILED,
      ],
    },
  });

export const acAccountRefreshToken = () => async (dispatch, getState: () => IStore) => {
  const { auth: { token } } = getState();
  if (token?.refresh_token) {
    await dispatch({
      [CALL_API]: {
        body: `grant_type=refresh_token&refresh_token=${token?.refresh_token}`,
        endpoint: loginUrl(),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: RequestMethods.POST_METHOD,
        types: [actions.ACCOUNT_TOKEN_REFRESH_REQUEST, actions.ACCOUNT_TOKEN_REFRESH_SUCCESS, actions.ACCOUNT_TOKEN_REFRESH_FAILED],
      },
    });
  }
};

// LOGIN Actions
export const acAccountLoginBase: (body, params) => any = (body, params) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      body,
      endpoint: loginUrl(),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: RequestMethods.POST_METHOD,
      requestParams: {
        ...params,
      },
      types: [actions.ACCOUNT_LOGIN_REQUEST, actions.ACCOUNT_LOGIN_SUCCESS, actions.ACCOUNT_LOGIN_FAILED],
    },
  }).then((response) => {
    if (!response.result) {
      if (response?.payload?.error_description === 'e.account.noConfirm') {
        dispatch(acPageSideMenuPageAction(ESideMenuPage.resendActivation));
        dispatch(acPageSideMenuStateAction(true));
      } else if (response?.payload) {
        // TODO handle SERVICE error
      }
    }
    if (response?.result) {
      dispatch(acPageSideMenuStateAction(false));
      return response;
    }
    return response;
  });

export const acAccountEmailLogin: (params: { email: string, password: string }) => any = (params) => (dispatch) => {
  const { email, password } = params;
  const body = `grant_type=password&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`;
  return dispatch(acAccountLoginBase(body, params));
};

export const acAccountFaceBookLogin: (params: { facebookuserid: string; facebooktoken: string }) => any = (params) => (dispatch) => {
  const { facebookuserid, facebooktoken } = params;
  const body = `grant_type=facebook&username=${facebookuserid}&password=${facebooktoken}`;
  return dispatch(acAccountLoginBase(body, params));
};

export const acAccountGoogleLogin: (data: CredentialResponse) => any = (data) => (dispatch) => {
  const { credential } = data;
  const body = `grant_type=google&password=${credential}`;
  return dispatch(acAccountLoginBase(body, { credential }));
};

export const acGoogleLogin = (data: CredentialResponse) => (dispatch) => {
  if (data.credential) {
    return dispatch(acAccountGoogleLogin(data)).then((response) => {
      if (!response?.result) {
        if (response?.payload?.error_description === 'e.google.canCreate') {
          return dispatch(acSignUpGoogle(data)).then((result) => {
            return dispatch(acGoogleLogin(data));
          });
        }
      }
      return response;
    });
  }
};

export const acFaceBookLoginLong = (data: IFaceBookTokenDetail) => (dispatch) => {
  return dispatch(acCustomerLinkFacebookAccountLong(data.accessToken || data.access_token || '')).then((res) => {
    if (res && res.payload?.json?.access_token) {
      return dispatch(acFaceBookLogin({ ...data, access_token: res.payload.json.access_token }));
    }
    return res;
  });
};

export const acFaceBookLogin = (data: IFaceBookTokenDetail, repeat?: boolean) => (dispatch) => {
  return dispatch(
    acAccountFaceBookLogin({
      facebooktoken: data.access_token || data.accessToken || '',
      facebookuserid: data.userID,
    }),
  ).then((response) => {
    if (!response?.result) {
      if (response.payload.error_description === 'e.facebook.canCreate' && !repeat) {
        return dispatch(acSignUpFacebook({ ...data, ...response.payload.extra_data }, data.access_token || data.accessToken || '')).then(() => {
          return dispatch(acFaceBookLogin(data, true));
        });
      }
    }
    return response;
  });
};

export const acLoginViaFBToken = (fbToken: string) => async (dispatch) => {
  if (fbToken) {
    return await dispatch(acFacebookMe(fbToken)).then(async (res) => {
      const userId = res?.payload?.id;
      if (userId) {
        const data: Partial<IFaceBookTokenDetail> = {
          accessToken: fbToken,
          userID: userId,
        } as IFaceBookTokenDetail;
        return await dispatch(acFaceBookLogin(data as IFaceBookTokenDetail)).then(async (res2) => {
          if (res2?.payload?.error === 'invalid_client') {
            dispatch(acPageSideMenuPageAction(ESideMenuPage.main));
            dispatch(acPageSideMenuStateAction(true));
          }
          return res2;
        });
      }
      return false;
    });
  } else {
    return false;
  }
};


export const acAccountAppleLogin: (data: IAppleResponseToken) => any = (data) => (dispatch) => {
  const { authorization: { id_token } } = data;
  const body = `grant_type=apple&password=${id_token}`;
  return dispatch(acAccountLoginBase(body, { id_token }));
};

export const acAppleLogin = (data: IAppleResponseToken) => (dispatch) => {
  if (data.authorization) {
    return dispatch(acAccountAppleLogin(data)).then((response) => {
      if (!response?.result) {
        if (response?.payload?.error_description === 'e.apple.canCreate') {
          dispatch(acAuthPrefillData({
            appleData: data,
            extra_data: data?.user?.name ? { ...convertCamelCaseObjectToHyphenObject(data.user.name), ...response?.payload.extra_data } : response?.payload.extra_data,
            show: true,
            socialType: ESocialTypes.apple,
          }));
        }
      }
      return response;
    });
  }
};
// END LOGIN Actions


// SIGN UP Actions
type TSignUpBase = (attributes: ISignUpParams) => any;
export const acSignUpBase: TSignUpBase = (attributes) => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  const locale = { country: countryCode, lang: languageCode };

  const switchTypes = (type) => {
    switch (type) {
      case 'email':
        return [actions.ACCOUNT_SIGNUP_REQUEST, actions.ACCOUNT_SIGNUP_SUCCESS, actions.ACCOUNT_SIGNUP_FAILED];
      case 'facebook':
        return [actions.ACCOUNT_SIGNUP_FACEBOOK_REQUEST, actions.ACCOUNT_SIGNUP_FACEBOOK_SUCCESS, actions.ACCOUNT_SIGNUP_FACEBOOK_FAILED];
      case 'google':
        return [actions.ACCOUNT_SIGNUP_GOOGLE_REQUEST, actions.ACCOUNT_SIGNUP_GOOGLE_SUCCESS, actions.ACCOUNT_SIGNUP_GOOGLE_FAILED];
      default:
        return [actions.ACCOUNT_SIGNUP_REQUEST, actions.ACCOUNT_SIGNUP_SUCCESS, actions.ACCOUNT_SIGNUP_FAILED];
    }
  };

  return dispatch({
    [CALL_API]: {
      body: {
        data: {
          attributes: {
            ...attributes,
          },
          type: 'customer',
        },
      },
      endpoint: addParamsToUrl(registrationUrl(), { ...locale }),
      method: RequestMethods.POST_METHOD,
      requestParams: { ...attributes },
      types: switchTypes(attributes['social-type']),
      subStore: true,
    },
  });
};

export interface ISignUpEmailParams {
  email: string;
  firstName?: string;
  password: string;
}

type TSignUpEmail = (params: ISignUpEmailParams) => any;
export const acSignUpEmail: TSignUpEmail = ({ email, firstName, password }) => (dispatch) =>
  dispatch(acSignUpBase({
    email,
    'first-name': firstName,
    password,
    'simple-mode': true,
  }));


type TSignUpGoogle = (data: CredentialResponse) => any;
export const acSignUpGoogle: TSignUpGoogle = (data) => (dispatch) => {
  const { credential } = data;
  const credentialArray = credential?.split('.');
  const decodedJson = credentialArray && credentialArray[1] && base64ToString2(credentialArray[1]);
  const decodedData = JSON.parse(decodedJson || '');

  const attributes: ISignUpParams = {
    email: decodedData?.email,
    'first-name': decodedData?.given_name,
    'last-name': decodedData?.family_name,
    'simple-mode': true,
    'social-token': credential || '',
    'social-type': 'google',
  };

  return dispatch(acSignUpBase(attributes));
};

type TSignUpFacebook = (data: IFaceBookTokenDetail, accessToken: string) => any;
export const acSignUpFacebook: TSignUpFacebook = (data, accessToken) => (dispatch, getState: () => IStore) => {

  const firstName = getNameFromFB(data);
  const attributes: ISignUpParams = {
    email: data.email,
    'first-name': firstName ? firstName : data.firstName || undefined,
    'last-name': data.lastName || undefined,
    'simple-mode': true,
    'social-token': accessToken || '',
    'social-type': 'facebook',
    'social-user-id': data.userID || data.userId,
  };

  return dispatch(acSignUpBase(attributes));
};

type TSignUpApple = (data: IAppleResponseToken, formData: IUserDataAttributes) => any;
export const acSignUpApple: TSignUpApple = (data, formData) => (dispatch) => {

  const attributes: ISignUpParams = {
    'social-token': data?.authorization?.id_token || '',
    'social-type': 'apple',
    ...formData,
  };
  if (data?.user) {
    attributes['social-user-id'] = objectToBase64(data.user);
  }

  return dispatch(acSignUpBase(attributes));
};

export const acSignUpFacebookLong = (data: IFaceBookTokenDetail) => (dispatch) => {
  return dispatch(acCustomerLinkFacebookAccountLong(data.accessToken || data.access_token || '')).then(
    (res) => {
      if (res && res.payload.json.access_token) {
        return dispatch(acSignUpFacebook(data, res.payload.json.access_token)).then((responseCreate) => {
          if (responseCreate.result) {
            dispatch(acPageSideMenuPageAction(ESideMenuPage.registrationSuccessful));
          }
          return responseCreate;
        });
      }
    },
  );
};

export interface IAccountSmartIdLoginParams {
  password: string;
  user: string;
}

export const acAccountSmartIdLogin: (data: IAccountSmartIdLoginParams) => any = (data) => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode } } = getState();
  const { user, password } = data;
  const body = `grant_type=smart_id&username=${user}&password=${encodeURIComponent(password)}&country=${countryCode}`;
  return dispatch(acAccountLoginBase(body, {}));
};

export const acAccountSmartIdGetPin: () => any = () => (dispatch: any) => dispatch({
  [CALL_API]: {
    endpoint: customerSmartIdTokenUrl(),
    method: RequestMethods.POST_METHOD,
    types: [actions.AUTH_SMART_ID_REQUEST, actions.AUTH_SMART_ID_SUCCESS, actions.AUTH_SMART_ID_FAILED],
  },
});

type TSmartIdPersonalCodeSubmit = (personalCode: string) => any;
export const acAccountSmartIdPersonalCodeSubmit: TSmartIdPersonalCodeSubmit = (personalCode) => (dispatch: any) => {
  dispatch(acAccountSmartIdGetPin()).then((res) => {
    if (res && res.result && res.payload) {
      dispatch(acAccountSmartIdLogin({ user: personalCode, password: res.payload.hash })).then((response) => {
        if (!response?.result) {
          if (response?.payload?.error_description === 'e.smartid.canCreate') {
            const { dateOfBirthday, firstName, lastName, password, ...mappedData } = response?.payload.extra_data;
            mappedData.dateOfBirth = dateOfBirthday && format(new Date(dateOfBirthday), customerApiDateFormat);
            mappedData.firstName = firstName && capitalizeFirstLetter(firstName);
            mappedData.lastName = lastName && capitalizeFirstLetter(lastName);
            mappedData['social-token'] = password;
            mappedData[AccountAttributesFields.gender] = getGenderByNationalId(mappedData.identityCode);
            dispatch(acAuthPrefillData({
              extra_data: convertCamelCaseObjectToHyphenObject(mappedData),
              show: true,
              socialType: ESocialTypes.smartId,
            }));
            dispatch(acPageSideMenuStateAction(false));
          }
        } else {
          dispatch(acPageSideMenuStateAction(false));
        }
      });
    }
  });
};


type TSignUpSmartId = (data: IUserDataAttributes) => any;
export const acSignUpSmartId: TSignUpSmartId = (data) => (dispatch, getState: () => IStore) => {
  const { auth: { signUpPrefill: { extra_data } } } = getState();

  const attributes: ISignUpParams = {
    ...data,
    [AccountAttributesFields.dateOfBirth]: data[AccountAttributesFields.dateOfBirth] + '',
    //   'social-token': hash || '',
    'social-type': ESocialTypes.smartId,
    'social-user-id': extra_data?.['identity-code'],
  };

  return dispatch(acSignUpBase(attributes));
};

export interface IAttachSmartIdAttr {
  [AccountAttributesFields.nationalId]: string;
  password: string;
  [AccountAttributesFields.email]: string;
}

export const acAttachSmartId: (attributes: IAttachSmartIdAttr) => any = (attributes) => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  const path = `${window.location.origin}/${countryCode || 'EE'}/${languageCode || 'et'}`;
  return dispatch({
    [CALL_API]: {
      body: {
        data: {
          attributes: {
            ...attributes,
            'confirmation-url': `${path}?action=${smartIdFinalize}&token=$token`,
          },
        },
      },
      endpoint: addParamsToUrl(customerSmartIdAttachUrl(), {
        country: countryCode || 'EE',
        lang: languageCode || 'et',
      }),
      method: RequestMethods.POST_METHOD,
      types: [actions.ATTACH_SMART_ID_REQUEST, actions.ATTACH_SMART_ID_SUCCESS, actions.ATTACH_SMART_ID_FAILED],
    },
  });
};

export const acAttachSmartIdFinalize: (token: string) => any = (token) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      body: {},
      endpoint: customerSmartIdAttachFinalizeUrl(token),
      method: RequestMethods.POST_METHOD,
      types: [actions.ATTACH_SMART_ID_FINALIZE_REQUEST, actions.ATTACH_SMART_ID_FINALIZE_SUCCESS, actions.ATTACH_SMART_ID_FINALIZE_FAILED],
    },
  });
};
// END SIGN UP Actions

export const acRegisterCustomer: (customer: any) => any = (customer) => (dispatch: any) => dispatch({
  [CALL_API]: {
    body: {
      data: {
        attributes: {
          ...customer,
          ['no-password']: true,
        },
        type: 'customer',
      },
    },
    endpoint: registrationUrl(),
    method: RequestMethods.POST_METHOD,
    subStore: true,
    types: [actions.LOYALTY_PROGRAM_REGISTER_REQUEST, actions.LOYALTY_PROGRAM_REGISTER_SUCCESS, actions.LOYALTY_PROGRAM_REGISTER_FAILED],
  },
});

export const acGetCustomerSignUpData: (id: string) => any = (id) => (dispatch: any) => dispatch({
  [CALL_API]: {
    endpoint: getCustomerSignupDataUrl(id),
    method: RequestMethods.GET_METHOD,
    types: [loyaltyProgramActions.LOYALTY_PROGRAM_SIGN_UP_DATA_REQUEST, loyaltyProgramActions.LOYALTY_PROGRAM_SIGN_UP_DATA_SUCCESS, loyaltyProgramActions.LOYALTY_PROGRAM_SIGN_UP_DATA_FAILED],
  },
});


export const acForgotPassword: (params: { email: string; path: string }, locale: { lang; country }) => any = (
  { email, path },
  locale,
) => (dispatch) =>
  dispatch({
    [CALL_API]: {
      body: {
        data: {
          attributes: {
            email,
            ['confirmation-url']: `${path}?type=${passwordResetKey}&token=$token`,
          },
          type: 'customer-remind-pwd',
        },
      },
      endpoint: addParamsToUrl(getCustomerForgotLinkUrl(), { ...locale }),
      method: RequestMethods.POST_METHOD,
      subStore: true,
      types: [actions.ACCOUNT_FORGOT_REQUEST, actions.ACCOUNT_FORGOT_SUCCESS, actions.ACCOUNT_FORGOT_FAILED],
    },
  });

export const acResetPasswordConfirmation = (password: string) => (dispatch, getState: () => IStore) => {
  const { context: { query } } = getState();
  const token = query.token || '';
  return dispatch({
    [CALL_API]: {
      body: {
        data: {
          attributes: {
            password,
          },
          type: 'customer-reset-pw',
        },
      },
      endpoint: getCustomerResetPasswordUrl(token),
      method: RequestMethods.PATCH_METHOD,
      types: [
        actions.ACCOUNT_RESET_PASSWORD_REQUEST,
        actions.ACCOUNT_RESET_PASSWORD_SUCCESS,
        actions.ACCOUNT_RESET_PASSWORD_FAILED,
      ],
    },
  });
};
export const acResetPasswordCheck = (token: string) => (dispatch, getState: () => IStore) => {
  return dispatch({
    [CALL_API]: {
      endpoint: getCustomerResetPasswordUrl(token),
      method: RequestMethods.GET_METHOD,
      types: [
        actions.ACCOUNT_RESET_PASSWORD_CHECK_REQUEST,
        actions.ACCOUNT_RESET_PASSWORD_CHECK_SUCCESS,
        actions.ACCOUNT_RESET_PASSWORD_CHECK_FAILED,
      ],
    },
  });
};

export const acAccountLogout = () => async (dispatch) => {
  resetToken(null);
  await dispatch({
    type: actions.AUTH_LOGOUT,
  });
  if (typeof window !== 'undefined') {
    // @ts-ignore
    const fbUserId = window?.FB?.getUserID();
    if (fbUserId?.length > 0) {
      // @ts-ignore
      window?.FB?.logout();
    }
  }
  if (!mobileMode) {
    // document.location.href = '/';
    await dispatch(acGoToPage(PageTypes.home, true));
  }
  return Promise.resolve(true);
};

export const acAccountTokenExpired = () => async (dispatch) => {
  dispatch({
    type: actions.AUTH_TOKEN_EXPIRED,
  });
  await dispatch(acAccountRefreshToken());
};

type TCustomerResendConfirmation = () => any;
export const acCustomerResendConfirmation: TCustomerResendConfirmation = () => (dispatch, getState: () => IStore) => {
  const { auth: { loginData }, pageData: { countryCode, languageCode } } = getState();
  const attributes: {
    email?: string;
  } = {};
  if (loginData.email) {
    attributes.email = loginData.email;
  }
  if (loginData.facebookuserid) {
    attributes['social-user-id'] = loginData.facebookuserid;
  }
  if (loginData.facebooktoken) {
    attributes['social-token'] = loginData.facebooktoken;
  }
  // social-user-id and social-token should be included in request.
  return dispatch({
    [CALL_API]: {
      body: {
        data: {
          attributes,
          type: 'customer-remind-finalizes',
        },
      },
      endpoint: customerRemindFinalizesUrl(countryCode, languageCode),
      method: RequestMethods.POST_METHOD,
      subStore: true,
      types: [
        actions.ACCOUNT_RESEND_CONFIRMATION_REQUEST,
        actions.ACCOUNT_RESEND_CONFIRMATION_SUCCESS,
        actions.ACCOUNT_RESEND_CONFIRMATION_FAILED,
      ],
    },
  });
};

export const acGetSocialApps = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      cache: true,
      cacheOptions: CacheFetchOptions.getSocials,
      endpoint: customerSocialsClientsUrl(),
      method: RequestMethods.GET_METHOD,
      types: [
        actions.AUTH_SOCIALS_CLIENTS_REQUEST,
        actions.AUTH_SOCIALS_CLIENTS_SUCCESS,
        actions.AUTH_SOCIALS_CLIENTS_FAILED,
      ],
    },
  });
};


type TAuthPrefill = (props: IAuthPrefillProps) => any;
export const acAuthPrefillData: TAuthPrefill = (props) => ({
  payload: props,
  type: actions.AUTH_PREFILL,
});


type TAuthClearSignUpErrors = (props: AccountAttributesFields) => IAuthClearSignUpErrors;
export const acAuthClearSignUpErrors: TAuthClearSignUpErrors = (props) => ({
  payload: props,
  type: actions.AUTH_CLEAR_SIGNUP_ERRORS,
});
