import { Middleware, MiddlewareAPI } from 'redux';

import { maileBeauty } from '@constants/config';
import { TContext } from '@utils/cookies';
import { acAlertShowAction } from '@actions/acAlert';
import { acAccountTokenExpired, acAuthCreateSessionAction } from '@actions/acAuth';
import { RequestMethods } from '@constants/types';
import { ActionStatuses, ICallApi, IFetchError, IMiddlewareErrorResponse, NO_CACHE } from '@interfaces/fetchService';
import { EStoreID, TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';
import { getCachedData, setCachedData } from './Cache';

import { actionWith, callApi } from './FetchAPI';
import { replaceSessionIdInUrl } from './utils';

export const CALL_API = Symbol('Call API');

export type TRequestAction = Promise<any>;

let retryTime: number | null = null;
const retryLimit: number = 120 * 100; // milliseconds between same request, to prevent requests loop

const service: (context?: TContext) => Middleware = (context) => (store: MiddlewareAPI<TThunkDispatch, IStore>) => (next) => async (action) => {
  const responsesArray = [200, 202, 203];
  const callAPI: ICallApi = action[CALL_API];

  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { body, cache, cacheOptions, endpoint, requestParams, headers, method, types, status, subStore } = callAPI;

  if (!method) {
    throw new Error('method does not exist');
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every((type) => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  // tslint:disable-next-line:prefer-const
  let [requestType, successType, failureType] = types;

  next(actionWith(action, { requestParams, type: requestType }));

  const isServer = typeof window === 'undefined' ? 1 : 0;
  const { pageData: { data: { siteSettings } } }: IStore = store.getState();
  const disableReactCache: boolean = siteSettings?.disableReactCache || false;
  const lruCacheEnabled = isServer && !disableReactCache;

  const request: (repeat?: boolean, replaceSession?: boolean) => Promise<any> = async (repeat, replaceSession) => {
    const {
      auth: { gaId, sessionId, token },
      context: { userAgent },
    }: IStore = store.getState();
    let newEndpoint = endpoint;
    if (endpoint.includes('/v2/') && gaId) {
      newEndpoint += endpoint.includes('?') ? '&' : '?';
      newEndpoint += 'ga=' + gaId;
    }
    if (maileBeauty && subStore) {
      newEndpoint += endpoint.includes('?') ? '&' : '?';
      newEndpoint += 'subStore=' + EStoreID.MAILEBEAUTY;
      if (method !== RequestMethods.GET_METHOD) {
        body.subStore = EStoreID.MAILEBEAUTY;
      }
    }

    if (repeat && replaceSession) {
      if (method === RequestMethods.GET_METHOD) {
        newEndpoint = replaceSessionIdInUrl(newEndpoint, sessionId);
      } else {
        if (body.hasOwnProperty('sessionId')) {
          body.sessionId = sessionId;
        }
      }
    }

    const requestAction: TRequestAction = (cache && !repeat && lruCacheEnabled) ? getCachedData(newEndpoint, method) : callApi(newEndpoint, method, body, token, headers || null, userAgent);

    return requestAction.then(
      async (response) => {
        const result = response.json;
        if (typeof result !== 'string') {
          next(
            actionWith(
              action,
              Object.assign(
                {},
                { type: responsesArray.includes(response.status) ? successType : failureType },
                { payload: result, requestParams },
              ),
            ),
          );
          if (cache && typeof window === 'undefined' && method === RequestMethods.GET_METHOD) {
            setCachedData(newEndpoint, result, cacheOptions);
          }
        } else if (response.status === 200) {
          next(
            actionWith(
              action,
              Object.assign({}, { type: responsesArray.includes(response.status) ? successType : failureType }),
            ),
          );
        }

        const responseTo: any = { result: true, payload: result, status: 200 };
        return responseTo;
      },
      async (error: IFetchError | any) => {
        const response: IMiddlewareErrorResponse = {
          payload: error.body,
          result: false,
          status: error.status,
          type: failureType,
        };
// Todo handle 1011
        if ([400, 401].includes(error.status) && error.body && !repeat) {
          if (Array.isArray(error.body)) {
            if (([1010, 1011].includes(error.body[0]?.errorCode)) || error.body?.ErrorCode === 401) {
              const currentTime = new Date().getTime();
              if (!retryTime || currentTime > (retryTime + retryLimit)) {
                await store.dispatch(acAccountTokenExpired());
                retryTime = currentTime;
                return request(true);
              }
            }
            if (([6000].includes(error.body[0]?.errorCode))) {
              await store.dispatch(acAuthCreateSessionAction(context));
              return request(true, true);
            }
          }
        }
        if ([NO_CACHE].includes(error.code) && !repeat) {
          return request(true, false);
        }
        // Repeat request if server was not available
        if (error.status && [503].includes(error.status) && !repeat) {
          return request(true, false);
        }

        next(actionWith(action, response));
        if (
          status &&
          status.key &&
          [ActionStatuses.ENABLED_FAILURE_STATUS_BAR, ActionStatuses.ENABLED_STATUS_BAR].includes(status.key)
        ) {
          store.dispatch(acAlertShowAction(error.message));
        }
        return response;
      },
    );
  };
  return request();
};

export default service;
